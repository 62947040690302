var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"修改丧属"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"丧属姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入丧属姓名' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入丧属姓名' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone_number', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入联系电话' },
              { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确' }
            ]
          }]),expression:"['phone_number', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入联系电话' },\n              { pattern: /^1[3-9]\\d{9}$/, message: '手机号格式不正确' }\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }